import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404" />
    <div className="container">
      <h1>Oh non !</h1>
      <p>Cette page n'existe pas !</p>
    </div>
  </Layout>
)

export default NotFoundPage
